<template>
  <form action="" @submit.stop.prevent="() => {}">
    <!-- <b-form-group v-if="isOrganized">
      <b-form-radio-group v-model="selectFamilyAll" name="radio-sub-category">
        <b-form-radio value="all">{{
          $t('search-tab.all-other')
        }}</b-form-radio>
        <b-form-radio value="family">{{
          $t('search-tab.family-tours')
        }}</b-form-radio>
      </b-form-radio-group>
    </b-form-group> -->

    <div class="form-row" v-if="isOdysseySite" :class="{ 'col-md-12 mt-1': type!=='tabs' }">
      <b-form-group id="input-group-2" class="agent-select" label-for="input-2" :class="type==='tabs' ? 'col-md-3': 'col-md-12'">
        <v-select
          v-model="agent"
          :reduce="agent => agent.value"
          :options="agentList"
          dir="rtl"
          :clearable="false"
          :searchable="true"
          :disabled="loginOdyAgentCode!==changeEnableAgentCode"
        >
        </v-select>
      </b-form-group>
    </div>
    <div class="form-row desktop_form multi-select-sticky" v-if="type === 'tabs'">
      <div class="form-group col-md-3">
        <select-destinations
          @changeDestination="changeDestination"
          :type="type"
          :category="category"
          :disabled="disableDestination"
        />
      </div>
      <div class="form-group col-md-2">
        <select-subjects
          @changeSubject="changeSubject"
          :availableSubject="availableSubject"
          :type="type"
          :disabled="disableSubjects"
        />
      </div>
      <div class="form-group col-md-2">
        <select-months
          @changeMonths="changeMonths"
          :availableDates="availableDates"
          :type="type"
          :disabled="disableMonths"
        />
      </div>
      <div class="form-group col-md-3">
        <occupancy-pax
          @changeBodyCount="changeCount"
          :dropdownShow="dropdownShow"
          :disabled="disableOccupancy"
        />
      </div>
      <div class="form-group col-md-2 d-flex" style="height:3rem;">
        <button
          class="btn flex-fill"
          @click="submitSearch"
          :disabled="searchDisable"
        >
          {{ categoryId ? $t(`search-tab.search_${categoryId}`) : '' }}
          <b-spinner v-if="isLoading"></b-spinner>
          <i class="fas fa-search ml-3" v-else></i>
        </button>
      </div>
    </div>

    <!-- <MobileSearchForm/> -->
    <div class="card card-body boxSideSearch border-0 multi-select-sticky" :class="{ mobile_form: type === 'tabs' }">
      <div class="form-row">
        <div class="form-group col-12">
          <!-- <label for="serchItem">{{ $t('search-tab.mobile-form.destination-label') }}</label> -->
          <select-destinations
            @changeDestination="changeDestination"
            :type="type"
            :category="category"
            :disabled="disableDestination"
          />
        </div>
        <div class="form-group col-12">
          <!-- <label for="serchItem">{{ $t('search-tab.mobile-form.subject-label') }}</label> -->
          <select-subjects
          @changeSubject="changeSubject"
          :availableSubject="availableSubject"
          :type="type"
          :disabled="disableSubjects"
        />
        </div>
        <div class="form-group col-12">
          <!-- <label for="startDate">{{ $t('search-tab.mobile-form.start-date') }}</label> -->
          <select-months
            @changeMonths="changeMonths"
            :availableDates="availableDates"
            :type="type"
            :disabled="disableMonths"
          />
        </div>
        <div class="form-group col-4">
          <b-form-select class="form-control" v-model="countAdult" :disabled="disableOccupancy">
            <template #first>
              <b-form-select-option :value="0" disabled>{{ $t('search-tab.adult') }}</b-form-select-option>
            </template>
            <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
          </b-form-select>
        </div>
        <div class="form-group col-4">
          <b-form-select class="form-control" v-model="countChild" :disabled="disableOccupancy">
            <template #first>
              <b-form-select-option :value="0" disabled>{{ $t('search-tab.child') }}</b-form-select-option>
            </template>
            <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
          </b-form-select>
        </div>
        <div class="form-group col-4">
          <b-form-select class="form-control" v-model="countInfant" :disabled="disableOccupancy">
            <template #first>
              <b-form-select-option :value="0" disabled>{{
                $t('search-tab.infant')
              }}</b-form-select-option>
            </template>
            <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
          </b-form-select>
        </div>
        <div class="form-group d-flex">
          <button
            class="btn flex-fill btn-outline-dark btn-search mobile"
            @click="submitSearch"
            :disabled="searchDisable"
          >
            {{ categoryId ? $t(`search-tab.search_${categoryId}`): '' }}
            <b-spinner v-if="isLoading" small></b-spinner>
            <i class="fas fa-search" v-else></i>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import {
  // BFormGroup,
  // BFormRadioGroup,
  // BFormRadio,
  BFormSelect,
  BFormSelectOption,
  BSpinner,
  BFormGroup,
} from 'bootstrap-vue';
import TemplateSearchTabOT from './TemplateSearchTabOT';

export default {
  name: 'SearchTab',
  components: {
    OccupancyPax: () => import('@/components/atoms/occupancyPax/OccupancyPaxTheme0'),
    SelectDestinations: () => import('@/components/search/atoms/SelectDestinations'),
    SelectSubjects: () => import('@/components/search/atoms/SelectSubjects'),
    SelectMonths: () => import('@/components/search/atoms/SelectMonths'),
    // BFormGroup,
    // BFormRadioGroup,
    // BFormRadio,
    BFormSelect,
    BFormSelectOption,
    BSpinner,
    BFormGroup,
    vSelect,
  },
  extends: TemplateSearchTabOT,
};
</script>

<style scoped>
@media (max-width: 479px) {
  .mobile_form {
    display: block;
  }
  .desktop_form {
    display: none;
  }
  .btn-search{
    padding-right: 20px;
  }
}
.custom-control {
  padding-right: 1rem;
}
.form-group.agent-select {
  margin-bottom: 1rem;
}
</style>

<style>
.custom-control-input {
  left: auto;
  right: 0;
}
.filter-tab .custom-control-label::before,
.filter-tab .custom-control-label::after {
  right: -1.5rem;
  left: auto;
}
.vs__dropdown-toggle {
  background: #e9ecef !important;
}
.agent-select .vs__dropdown-toggle {
  border-radius: 50px;
  padding: 0.575rem 1.35rem;
  height: 3rem;
  cursor: pointer;
}
</style>
