<template>
  <div>Template Search Tab Organize Tour</div>
</template>

<script>
import { mapGetters } from 'vuex';

const { VUE_APP_ENABLE_AGENT_CODE } = process.env;

export default {
  name: 'SearchTab',
  props: {
    category: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: 'tabs',
    },
    agentList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      isLanding: 'GET_IS_LANDING_PAGE',
      landingInfo: 'GET_LANDING_INFO',
      marketerId: 'GET_MARKETER_ID',
      searchContent: 'GET_SEARCH_CONTENT',
      calendarData: 'GET_CALENDAR_DATA',
      subjectCategory: 'GET_SUBJECTS_CATEGORY',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      odyAgentCode: 'GET_SELECTED_ODYSSEY_AGENT_CODE',
      loginOdyAgentCode: 'GET_LOGIN_ODYSSEY_AGENT_CODE',
      isFcAgentMarketerMode: 'GET_FC_AGENT_MARKETER_MODE',
    }),
    destinations() {
      return this.searchContent?.dest || [];
    },
    months() {
      return this.searchContent?.month || [];
    },
    subjects() {
      return this.searchContent?.subject || [];
    },
    countAdult: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.adult ? this.$store.getters.GET_SEARCH_CONTENT.adult : 2;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['adult', value]);
      },
    },
    countChild: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.child ? this.$store.getters.GET_SEARCH_CONTENT.child : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['child', value]);
      },
    },
    countInfant: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.infant ? this.$store.getters.GET_SEARCH_CONTENT.infant : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['infant', value]);
      },
    },
    searchDisable() {
      return (
        this.destinations.length === 0 || this.subjects.length === 0 || this.months.length === 0 || !this.countAdult
      );
    },
    availableDates() {
      const { category, calendarData, destinations, subjects } = this;
      if (!category || !calendarData || !destinations || !subjects) return [];

      const subjectList = subjects.reduce((total, item) => (total.concat(item.list)), []);
      return calendarData.filter(
        (rec) => subjectList.indexOf(rec.sub) > -1 && destinations.findIndex((d) => (d?.value === rec.dest)) > -1,
      );
    },
    availableSubject() {
      const { category, calendarData, destinations, subjectCategory } = this;
      if (!category || !calendarData || !destinations || !subjectCategory) return [];
      const ableDate = calendarData.filter(
        (rec) => destinations.findIndex((d) => (d?.value === rec.dest)) > -1 && category.subjectCodes.includes(rec.sub),
      );

      return subjectCategory.filter((sub) => (ableDate.findIndex((date) => (sub.subjectCodes.includes(date.sub))) > -1));
    },
    disableDestination() {
      return this.isOdysseySite && (this.agent === '' || this.agent === null);
    },
    disableSubjects() {
      return this.destinations.length === 0;
    },
    disableMonths() {
      return this.subjects.length === 0;
    },
    disableOccupancy() {
      return this.months.length === 0;
    },
    isDomainVercel() {
      return window.location.host.includes('vercel') || window.location.host.includes('localhost');
    },
  },
  watch: {
    category: 'changedCategory',
    lang() {},
    agent() {
      this.$store.commit('SET_SELECTED_ODYSSEY_AGENT_CODE', this.agent);
    },
  },
  data() {
    return {
      categoryId: null,
      isOrganized: false,

      selectFamilyAll: 'all',
      dropdownShow: false,
      agent: '',
      changeEnableAgentCode: VUE_APP_ENABLE_AGENT_CODE,
    };
  },
  created() {
    this.agent = this.odyAgentCode;
  },
  async beforeMount() {
    // if (!this.calendarData) {
    //   await this.$store.dispatch('FETCH_CALENDAR_DATA');
    // }
    this.changedCategory();
  },
  methods: {
    async submitSearch(event) {
      event.preventDefault();
      const categoryId = this.category.dealTypeCode;
      this.$store.dispatch('UPDATE_CURRENT_CATEGORY', { categoryId });
      if (this.isLoading) {
        this.$bvModal.msgBoxOk(
          this.$t('search-tab.is-loading-warning-message'),
          {
            title: this.$t('search-tab.is-loading-warning-message-title'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          },
        );
        return false;
      }
      if (this.type === 'mobile') {
        this.$emit('closeSearchMobile');
      }

      if (this.searchDisable) {
        return false;
      }
      const body = {
          dest: encodeURIComponent(this.destinations.map((item) => item.value).join(',')),
          yearMonth: this.months.map((item) => item.value).join(','),
          subjectIds: this.subjects.map((item) => item.value).join(','),
          categoryId: this.category.code,
          adult: this.countAdult,
          child: this.countChild,
          infant: this.countInfant,
          odyAgentCode: this.agent,
        }, path = '/search-result';
      if (this.$route.query.utm_source) body.utm_source = this.$route.query.utm_source;
      if (this.isLanding) body.utm_source = this.landingInfo.utm_source;
      if (this.marketerId) body.marketerId = this.marketerId;
      if (this.$route.query.secret) body.secret = this.$route.query.secret; // temporary param for Ran's search
      if (this.isFcAgentMarketerMode) body['fc-agent-mode'] = '';
      const { query } = this.$route;
      if (Object.keys(body).every((key) => String(body[key]) === String(query[key]))) return false;

      this.$router.push({ path, query: body });

      if (this.isLanding) {
        const queryString = `${path}?${new URLSearchParams(body).toString()}`;
        window.location.href = queryString;
      }
      return true;
    },
    resetDate() {
      this.$store.dispatch('SET_SEARCH_ITEM', ['month', []]);
    },
    async changeDestination(pDest) {
      if (!pDest || (Array.isArray(pDest) && !pDest[0])) return;
      const categorySubjectIdList = this.category.subjectCodes.map(
        (item) => item,
      );
      await this.$store.dispatch('FETCH_CALENDAR_DATA', { dest: pDest.map((d) => (d.value)), subjects: categorySubjectIdList });

      this.$store.dispatch('SET_SEARCH_ITEM', ['dest', pDest]);
      this.$store.dispatch('SET_SEARCH_ITEM', ['subject', []]);
      this.$store.dispatch('SET_SEARCH_ITEM', ['month', []]);
    },
    changeSubject(pSubject) {
      if (!pSubject) return;
      this.$store.dispatch('SET_SEARCH_ITEM', ['subject', pSubject]);
      this.$store.dispatch('SET_SEARCH_ITEM', ['month', []]);
    },
    changeMonths(pMonths) {
      if (!pMonths) return;
      this.$store.dispatch('SET_SEARCH_ITEM', ['month', pMonths]);
    },
    changeCount({ adult, child, infant }) {
      this.countAdult = adult;
      this.countChild = child;
      this.countInfant = infant;
    },
    changedCategory() {
      this.categoryId = this.category.code;
      this.isOrganized = this.category.code === 'Organize_tour_packages';
    },
  },
};
</script>

<style scoped>
@media (max-width: 479px) {
  .mobile_form {
    display: block;
  }
  .desktop_form {
    display: none;
  }
  .btn-search{
    padding-right: 20px;
  }
}
.custom-control {
  padding-right: 1rem;
}
.form-group.agent-select {
  margin-bottom: 1rem;
}
</style>

<style>
.custom-control-input {
  left: auto;
  right: 0;
}
.filter-tab .custom-control-label::before,
.filter-tab .custom-control-label::after {
  right: -1.5rem;
  left: auto;
}
.vs__dropdown-toggle {
  background: #e9ecef !important;
}
.agent-select .vs__dropdown-toggle {
  border-radius: 50px;
  padding: 0.575rem 1.35rem;
  height: 3rem;
  cursor: pointer;
}
</style>
